<template>
    <Input v-model="inputValue" 
        :type="type"
        ref="password"
        autocomplete="off"
        :class="classes"
        :placeholder="placeholder"
        @input="inputChange"></Input>
</template>
<script>
export default {
    name: 'CustomPassword',
    props: {
       value: {
          type: String,
          default: ''
       },
       placeholder: String
    },
    data(){
        return {
            type: 'text',
            inputValue: '',
            classes: []
        }
    },
    mounted() {
       let nav = window.navigator.userAgent
       if(nav.includes('chrome') > -1){
           this.type = 'text';
           this.classes = ['custom-password'];
       }else {
           this.type = 'password';
       }
    },
    methods: {
       inputChange(val){
           this.$emit('input', val);
       }
    },
    watch: {
       value: {
           immediate: true,
           handler:function(val){
              this.inputValue = val;
           }
       }
    }
}
</script>
<style>
.custom-password .ivu-input{
   -webkit-text-security: disc;
}
</style>
